let body = null;
let languageCode = null;

/**
 * Get body element.
 * @returns {Element}
 */
function getBody() {
    if (body === null) {
        body = document.querySelector('body');
    }

    return body;
}

function getScrollContainer() {
    return document.getElementById('scroll-container') || getBody();
}

function getScrollbarWidth() {
    const scrollContainer = getScrollContainer();

    if (scrollContainer.nodeName === 'BODY') {
        if (scrollContainer.scrollHeight > window.innerHeight) {
            return window.innerWidth - document.documentElement.clientWidth;
        }
    } else {
        return scrollContainer.offsetWidth - scrollContainer.clientWidth;
    }

    return 0;
}

function getLanguageCode() {
    if (languageCode === null) {
        languageCode = document.documentElement.lang.split('-')[0];
    }

    return languageCode;
}

function getMetaValue(metaName) {
    const metaEl = document.querySelector(`meta[name='${metaName}']`);
    if (!metaEl) {
        return '';
    }

    return metaEl.getAttribute('content');
}

function onDomReady(fn) {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        window.setTimeout(fn, 1);
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

/**
 * Not really fully loaded, but close enough.
 * @param {Function} fn
 */
function onFullyLoaded(fn) {
    window.addEventListener('load', () => {
        fn();
    });
}

/**
 * Request animation frame as a promise.
 * @returns {Promise<void>}
 */
function asyncRaf() {
    return new Promise((resolve) => {
        window.requestAnimationFrame(resolve);
    });
}

export {
    onDomReady,
    onFullyLoaded,
    getScrollContainer,
    getScrollbarWidth,
    asyncRaf,
    getBody,
    getLanguageCode,
    getMetaValue
};
