import SentryReporting from '@/services/SentryReporting';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import ClientInfoForm from './Components/Membership/ClientInfoForm';
import ConfirmationForm from './Components/Membership/ConfirmationForm';
import IdentityConfirmationForm from './Components/Membership/IdentityConfirmationForm';
import JobInfoForm from './Components/Membership/JobInfoForm';
import MembershipFeeForm from './Components/Membership/MembershipFeeForm';
import PlansInfoForm from './Components/Membership/PlansInfoForm';
import PoliticalVulnerabilityInfoForm from './Components/Membership/PoliticalVulnerabilityInfoForm';
import RegistrationForm from './Components/Membership/RegistrationForm';
import { registerSharedComponents } from './shared-vue-components';
import http from './utilities/axios';
import { getBody, getMetaValue, onDomReady } from './utilities/browser';
import './cookies';

const sentryDsn = getMetaValue('sentry-vue-dsn');
if (sentryDsn) {
    const sentryRelease = getMetaValue('sentry-vue-release') || '1.0.0';
    Sentry.init({
        Vue,
        release: sentryRelease,
        dsn: sentryDsn,
        integrations: [new Integrations.BrowserTracing()],
    });
}

Vue.prototype.$http = http;
Vue.prototype.$sentyReporting = new SentryReporting();

registerSharedComponents(Vue);
Vue.component('cc-registration-form', RegistrationForm);
Vue.component('cc-identity-confirmation-form', IdentityConfirmationForm);
Vue.component('cc-client-info-form', ClientInfoForm);
Vue.component('cc-job-info-form', JobInfoForm);
Vue.component('cc-plans-info-form', PlansInfoForm);
Vue.component('cc-political-vulnerability-info-form', PoliticalVulnerabilityInfoForm);
Vue.component('cc-confirmation-form', ConfirmationForm);
Vue.component('cc-membership-fee-form', MembershipFeeForm);

onDomReady(() => {
    setTimeout(() => {
        const w = parseFloat(getComputedStyle(getBody(), null).width.replace('px', ''));
        if (w < 991 && location.pathname.substring(1).length > 0) {
            location.href = '#app';
        }
    }, 300);

    // eslint-disable-next-line no-new
    new Vue({
        el: '#app',
    });
});

