import Axios from 'axios';
import { getMetaValue } from '../utilities/browser';

const token = getMetaValue('csrf-token');

if (!token) {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

const http = Axios.create({
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': token || undefined,
    },
});

export default http;
