import { Form, FormError, FormGroup } from '@/components/global/form';
import Button from './Components/Shared/Button';
import Tippy from './Components/Shared/Tippy';

export function registerSharedComponents(globalVue) {
    globalVue.component('v-form', Form);
    globalVue.component('v-form-group', FormGroup);
    globalVue.component('v-form-error', FormError);
    globalVue.component('v-button', Button);
    globalVue.component('cc-tippy', Tippy);
}
